import { createSelector } from 'reselect';
import { EnvironmentVariables, OnboardingApp } from '../environments';
import { ApplicationsType } from '../pages/access-requests/columns';
import { PartiesResponse } from '../entities/entitlements-v1/partiesResponse';
import { Roles } from '../store/invitations/types';
import findRoleByParties from './parties.util';
import { Applications } from '../entities/user-onboarding-v1/applications';
import { ErrorDetails } from './util';
import { Party } from '../entities/entitlements-v1/party';
import { PartiesResponseRole } from '../entities/entitlements-v1/partiesResponseRole';

export enum userOnboardingDomain {
  INVITATION = 'IN',
  ACCESSREQUEST = 'AR',
  ACCESSMANAGEMENT = 'AM',
}

export const formatApplicationErrorMessageDetailsOnly = (
  error: ErrorDetails,
  applicationName: string,
) => {
  let errorMessage = error?.description ?? '';
  if (
    error?.details &&
    error?.details?.length > 0 &&
    error?.details?.[0]?.message &&
    errorMessage.length > 0
  ) {
    const findMessage = error.details.find((item: { message: string }) =>
      item.message.includes(applicationName),
    )?.message;
    if (findMessage) {
      errorMessage = findMessage;
    } else {
      errorMessage = error?.details?.[0]?.message;
    }

    return errorMessage;
  }
};

/*
This function provides capability to control user onboading apps
per Envieonment > Role > Domain
1) Provides capability to on or off the apps per Env
2) Test Admin Role will have separate env attribute
3) If PCC-NON PROD present ignore other apps
*/
export const getUserOnboardingApps = createSelector(
  (env: EnvironmentVariables) => env,
  (_: EnvironmentVariables, partiesResponse: PartiesResponse) => partiesResponse,
  (_: EnvironmentVariables, _1: PartiesResponse, apps: ApplicationsType[]) => apps,
  (
    _: EnvironmentVariables,
    _1: PartiesResponse,
    _2: ApplicationsType[],
    domain: userOnboardingDomain,
  ) => domain,

  (env, partiesResponse, apps, domain) => {
    // test admin role will look for separate attribute from env
    let userOnboardingApps: OnboardingApp;
    if (findRoleByParties(partiesResponse?.parties, [Roles.TESTUSERADMIN])) {
      userOnboardingApps = env.testAdminUserOnboardingApps;
    } else if (findRoleByParties(partiesResponse?.parties, [Roles.USERMANAGEMENTADMIN_FM3])) {
      userOnboardingApps = env.applicationUserManagementFM3Apps;
    } else {
      userOnboardingApps = env.userOnboardingApps;
    }

    // if PCC-NON PROD present ignore other apps
    // temporarily commenting out. Enable this in Feb 21 I believe when needed.
    // const hasNonProdApp = ((userOnboardingApps && userOnboardingApps[domain]) || []).includes(
    //   INVITATION_APPLICATIONS.NONPRODPCC,
    // );
    // return hasNonProdApp
    //   ? apps.filter((app) => [INVITATION_APPLICATIONS.NONPRODPCC].includes(app.applicationId)) || []
    //   : apps?.filter((app) =>
    //       ((userOnboardingApps && userOnboardingApps[domain]) ?? []).includes(app.applicationId),
    //     ) ?? [];

    return (
      apps?.filter((app) => (userOnboardingApps?.[domain] || [])?.includes(app.applicationId)) || []
    );
  },
);

export const hasTestAdminRole = (partiesResponse?: PartiesResponse) => {
  if (partiesResponse !== undefined && partiesResponse.parties) {
    return partiesResponse.parties.find((party: Party) => {
      return party.roles.find((role: PartiesResponseRole) => {
        return role.roleName === 'User Management Test Admin';
      });
    });
  }
};

export const findApplicationById = (
  applicationMetadata: Array<Applications>,
  applicationId: string,
) => {
  return applicationMetadata?.find((application: Applications) => {
    return application.applicationId === applicationId;
  });
};
