import { Box, Link, Typography } from '@mui/material';
import TEXT from '../../../../globals/translation-map';
import { formatDate } from '../../../../utils/util';
import Status from '../../../../components/status';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CAT_COLOR from '../../../../globals/color-properties';
import PartyNameCell from './cells/party-name-cell/index';
import UserCell from './cells/user-cell';
import SortableTitleCell from './cells/header-sortable-title';
import i18n from 'i18next';

const statusMap = {
  PENDING: {
    status: 'Pending',
    color: CAT_COLOR.SAFETY_ORANGE,
  },
  IGNORED: {
    status: 'Pending',
    color: CAT_COLOR.GREY,
  },
  COMPLETED: {
    status: 'Completed',
    color: CAT_COLOR.GREEN,
  },
};

export type TableCel = {
  name: string;
  title: ReactElement | string;
  getCellValue: (row: any) => ReactElement;
  width: string;
};

const useUserLeadsTableColumns: () => TableCel[] = (): TableCel[] => {
  const { t } = useTranslation();
  const sortStatusMap = {
    [`${t(TEXT.COMMON.STATUS.PENDING)}:`]: t(TEXT.USER_LEADS.STATUS.PENDING_EXPLAINED),
    [`${t(TEXT.COMMON.STATUS.COMPLETED)}:`]: t(TEXT.USER_LEADS.STATUS.COMPLETED_EXPLAINED),
    [`${t(TEXT.COMMON.STATUS.IGNORED)}:`]: t(TEXT.USER_LEADS.STATUS.IGNORED_EXPLAINED),
  };

  return [
    {
      name: 'email',
      title: (
        <Box pl={4}>
          <SortableTitleCell
            title={t(TEXT.MY_CUSTOMERS.ASSOCIATE_USER.USER_EMAIL)}
            columnIdentifier="email"
          />
        </Box>
      ),
      getCellValue: (row: any) => {
        return (
          <Box pl={4}>
            <Link data-testid="user-lead-email-link" onClick={() => {}}>
              <Typography variant="h5">{row.email}</Typography>
            </Link>
          </Box>
        );
      },
      width: '23%',
    },
    {
      name: 'user',
      title: t(TEXT.COMMON.TITLE.USER),
      getCellValue: ({ firstName, lastName, username }) => (
        <UserCell firstName={firstName} lastName={lastName} username={username} />
      ),
      width: '23%',
    },
    {
      name: 'partyName',
      title: (
        <SortableTitleCell title={t(TEXT.COMMON.TITLE.CUSTOMER)} columnIdentifier="partyName" />
      ),
      getCellValue: ({ partyName, partyNumber }) => (
        <PartyNameCell partyName={partyName} partyNumber={partyNumber} />
      ),
      width: '23%',
    },
    {
      name: 'initiatedDate',
      title: (
        <SortableTitleCell
          title={t(TEXT.USER_LEADS.COLUMNS.INITIATE_DATE)}
          columnIdentifier="initiatedDate"
          sortOptions={[
            {
              key: 'DESC',
              value: i18n.t(TEXT.COMMON.SORT.MOST_RECENT),
            },
            {
              key: 'ASC',
              value: i18n.t(TEXT.COMMON.SORT.LEAST_RECENT),
            },
          ]}
        />
      ),
      getCellValue: (row: any) => {
        return (
          <Typography variant="h5">
            {formatDate(row?.accountDetails?.accountEnrollmentTime)}
          </Typography>
        );
      },
      width: '15%',
    },
    {
      name: 'status',
      title: (
        <SortableTitleCell
          title={t(TEXT.COMMON.TITLE.STATUS)}
          columnIdentifier="status"
          statusMap={sortStatusMap}
        />
      ),
      getCellValue: (row: { status: string }) => {
        //TODO correct ts when entities ready
        //@ts-ignore
        return <Status status={row.status} color={statusMap[row.status].color} />;
      },
      width: '15%',
    },
  ];
};

export default useUserLeadsTableColumns;
