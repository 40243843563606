import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useStyles from './styles';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import FormInput from '../../../../components/form-input';
import { useFormik } from 'formik';
import denyRequestValidationSchema from './validations';
import { UserAccessRequestResponse } from '../../../../entities/user-onboarding-v1/userAccessRequestResponse';
import AccessRequestsService from '../../../../services/access-requests';
import { formatDetailedErrorMessage, showNotification } from '../../../../utils/util';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../store';
import { UserAccessRequestUpdateDenyStatus } from '../../../../entities/user-onboarding-v1/models';
import Loading from '../../../../components/loading';
import TEXT from '../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';

type DenyAccessRequestProperties = {
  onCloseModal: Function;
  selectedAccessRequest: UserAccessRequestResponse;
};

const DenyAccessRequest = (properties: DenyAccessRequestProperties) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatchTs();
  const { filters } = useSelector((state: AppState) => state.accessRequest);
  const [updateReqestLoading, setUpdateReqestLoading] = useState(false);
  const { authInfo, environmentVariables } = useSelector((state: AppState) => state.login);
  const { onCloseModal, selectedAccessRequest } = properties;
  const handleClose = () => {
    onCloseModal(false);
  };

  const onApply = async (reason: string) => {
    const accessRequestService = new AccessRequestsService(
      authInfo,
      environmentVariables.userOnboardingV1,
    );

    const inputReq = {
      status: UserAccessRequestUpdateDenyStatus.DENIED,
      reason,
    };

    try {
      setUpdateReqestLoading(true);
      await accessRequestService.editAccessRequest(
        selectedAccessRequest.requestId as string,
        inputReq,
        filters?.selectedAccount,
      );
      showNotification(
        dispatch,
        'success',
        t(TEXT.ACCESS_REQUESTS_SERVICE.NOTIFICATIONS.EDIT_ACCESS_REQUEST_DENIED),
      );
      setUpdateReqestLoading(false);
    } catch (error: any) {
      showNotification(
        dispatch,
        'error',
        formatDetailedErrorMessage(error) ||
          t(TEXT.ACCESS_REQUESTS_SERVICE.ERRORS.EDIT_ACCESS_REQUEST_DENIED),
      );
    }
    setUpdateReqestLoading(false);
    onCloseModal(true);
  };

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: denyRequestValidationSchema,
    onSubmit: (values) => {
      onApply(values.reason);
    },
  });

  useEffect(() => {
    formik.validateForm();
  }, []);
  const loadingFlag = updateReqestLoading;
  return (
    <Dialog
      data-testid="deny-access-request-container"
      open={true}
      onClose={handleClose}
      maxWidth="xs"
    >
      {loadingFlag && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          position={'absolute'}
        >
          <Loading height={80} width={90} display={'block'} blocks={true} />
        </Box>
      )}
      <DialogTitle {...({ component: 'div' } as any)} data-testid="deny-access-request-title">
        <Typography variant="h3">Deny Request</Typography>
        <IconButton
          className={classes.iconPosition}
          onClick={handleClose}
          data-testid="deny-access-request-close-icon"
          size="large"
        >
          <CloseIcon color="primary" fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText component="div">
          {`Are you sure you want to deny this access request for ${
            selectedAccessRequest.username ?? ''
          }?`}
        </DialogContentText>
        <Box pt={3}>
          <Typography className={classes.bold}>Comments</Typography>
        </Box>
        <Box>
          <FormInput
            fullWidth
            label="Reason for denying request"
            inputAccessorKey="reason"
            {...formik}
            multiline={true}
            maxCharLength={500}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          data-testid="deny-access-request-close-button"
          variant="outlined"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          disabled={!formik.isValid}
          data-testid="deny-access-request-apply-button"
          onClick={() => onApply(formik.values.reason)}
          color="primary"
          variant="contained"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DenyAccessRequest;
