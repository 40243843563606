import {
  AssetManagementState,
  assetManagementActionNames,
  assetManagementActionTypes,
  AssetManagementPage,
} from './types';

const initialState: AssetManagementState = {
  addAsset: {},
  assetManagementData: [],
  assetAllManagementData: [],
  filters: {
    [AssetManagementPage.ASSETS]: {},
    [AssetManagementPage.MY_CUSTOMER_ASSETS]: {},
  },
  assetManagementSearchValue: {
    [AssetManagementPage.ASSETS]: '',
    [AssetManagementPage.MY_CUSTOMER_ASSETS]: '',
  },
  cursors: [],
  isLoadingAssetManagement: false,
  isLoadingAssetManagementCount: false,
  totalCount: 0,
  isEditAssetLoading: false,
  isLoadingUpdateOwnership: {},
  selectedAsset: {},
  existingRecord: {},
  showNoOwnership: false,
  isGetAssetDetailsLoading: false,
  showNoAddAsset: false,
  isExpireAssetLoading: false,
  loadingCatAssetMetadata: false,
  initialSerialNumber: undefined,
  isCreateDcDcnRelationshipLoading: false,
  ccidAssets: [],
};

const assetManagementReducer = (
  state: AssetManagementState = initialState,
  action: assetManagementActionTypes,
): AssetManagementState => {
  switch (action.type) {
    case assetManagementActionNames.SET_SELECTED_ASSET: {
      return {
        ...state,
        selectedAsset: action.payload,
      };
    }
    case assetManagementActionNames.CLEAR_SELECTED_ASSET: {
      return {
        ...state,
        selectedAsset: initialState.selectedAsset,
      };
    }
    case assetManagementActionNames.CLEAR_EXISTING_RECORD: {
      return {
        ...state,
        existingRecord: initialState.existingRecord,
      };
    }
    case assetManagementActionNames.SET_INITIAL_SERIAL_NUMBER: {
      return {
        ...state,
        initialSerialNumber: action.payload,
      };
    }
    case assetManagementActionNames.UPDATE_FILTER_BY_TYPE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.page]: action.payload.filters,
        },
      };
    }
    case assetManagementActionNames.CLEAR_ASSET_MANAGEMENT_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.page]: {},
        },
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.page]: action.payload.filters,
        },
      };
    }
    case assetManagementActionNames.UPDATE_CAT_ASSET_METADATA: {
      return {
        ...state,
        selectedAsset: {
          ...state.selectedAsset,
          metadata: action.payload,
        },
      };
    }
    case assetManagementActionNames.SET_CAT_ASSET_LOADING_METADATA: {
      return {
        ...state,
        loadingCatAssetMetadata: action.payload,
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT: {
      return {
        ...state,
        assetManagementData: action.payload,
      };
    }
    case assetManagementActionNames.SET_ALL_ASSET_MANAGEMENT: {
      return {
        ...state,
        assetAllManagementData: action.payload,
      };
    }

    case assetManagementActionNames.SET_CCID_ASSETS: {
      return {
        ...state,
        ccidAssets: action.payload,
      };
    }

    case assetManagementActionNames.SET_IS_LOADING_ASSET_MANAGEMENT_DATA: {
      return {
        ...state,
        isLoadingAssetManagement: action.payload,
      };
    }
    case assetManagementActionNames.SET_IS_LOADING_ASSET_MANAGEMENT_DATA_COUNT: {
      return {
        ...state,
        isLoadingAssetManagementCount: action.payload,
      };
    }
    case assetManagementActionNames.SET_IS_EDIT_ASSET_LOADING: {
      return {
        ...state,
        isEditAssetLoading: action.payload,
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT_SEARCH_VALUE: {
      return {
        ...state,
        assetManagementSearchValue: {
          ...state.assetManagementSearchValue,
          [action.payload.page]: action.payload.value,
        },
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT_COUNT: {
      return {
        ...state,
        totalCount: action.payload,
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT_CURSOR: {
      return {
        ...state,
        cursors: action.payload,
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT_ORDER_BY: {
      return {
        ...state,
        orderBy: action.payload,
      };
    }
    case assetManagementActionNames.SHOW_NO_OWNERSHIP: {
      return {
        ...state,
        showNoOwnership: action.payload,
      };
    }
    case assetManagementActionNames.SHOW_NO_ADD_ASSET: {
      return {
        ...state,
        showNoAddAsset: action.payload,
      };
    }
    case assetManagementActionNames.SET_ASSET_MANAGEMENT_SORT_BY: {
      return {
        ...state,
        sortBy: action.payload,
      };
    }

    case assetManagementActionNames.SET_OWNERSHIP_DETAILS: {
      return {
        ...state,
        addAsset: {
          ...state?.addAsset,
          ownershipDetails: action.payload,
        },
      };
    }
    case assetManagementActionNames.CLEAR_OWNERSHIP_DETAILS: {
      return {
        ...state,
        addAsset: {
          ...state?.addAsset,
          ownershipDetails: undefined,
        },
      };
    }
    case assetManagementActionNames.SET_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    }
    case assetManagementActionNames.CLEAR_SELECTED_CUSTOMER: {
      return {
        ...state,
        selectedCustomer: initialState.selectedCustomer,
      };
    }
    case assetManagementActionNames.SET_IS_UPDATE_OWNERSHIP_LOADING: {
      return {
        ...state,
        isLoadingUpdateOwnership: action.payload,
      };
    }
    case assetManagementActionNames.SET_IS_CREATE_DC_DCN_RELATIONSHIP_LOADING: {
      return {
        ...state,
        isCreateDcDcnRelationshipLoading: action.payload,
      };
    }
    case assetManagementActionNames.SET_IS_GET_ASSET_DETAILS_LOADING: {
      return {
        ...state,
        isGetAssetDetailsLoading: action.payload,
      };
    }
    case assetManagementActionNames.SET_OWNING_DEALER: {
      return {
        ...state,
        owningDealer: action.payload,
      };
    }
    case assetManagementActionNames.SET_EXPIRE_ASSET_LOADING: {
      return {
        ...state,
        isExpireAssetLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default assetManagementReducer;
