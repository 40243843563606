import React from 'react';
import { Box } from '@mui/material';
import VariableRowInfiniteScrollTable from '../../../../components/variable-row-infinite-scroll-table';
import getScreenWidth from '../../../../hooks/get-screen-width';
import { userLeadsTableData } from '../../dummy-data';

import useUserLeadsTableColumns, { TableCel } from './use-user-leads-table-columns';

const nonExpandedRowSizeBasedOnScreenRes = { xl: 80, lg: 80, md: 96, sm: 160, xs: 160 };
const expandedRowSizeBasedOnScreenRes = {
  xl: 180,
  lg: 180,
  md: 180,
  sm: 180,
  xs: 180,
};
const tableHeight = window.innerHeight - 0.34 * window.innerHeight;

const UserLeadsTable: React.FC = () => {
  const screenWidth = getScreenWidth();

  const userLeadsTableColumns: TableCel[] = useUserLeadsTableColumns();

  return (
    <Box>
      <VariableRowInfiniteScrollTable
        rows={userLeadsTableData}
        columns={userLeadsTableColumns}
        nonExpandedRowSizeBasedOnScreenRes={nonExpandedRowSizeBasedOnScreenRes}
        expandedRowSizeBasedOnScreenRes={expandedRowSizeBasedOnScreenRes}
        noDataComponent={<Box>no data</Box>}
        screenWidth={screenWidth}
        hideNoDataComponent={false}
        height={tableHeight}
        hasNextPage={false}
        isNextPageLoading={false}
        loadNextPage={() => {}}
        expandedRowFlag={false}
        expandedRowComponent={(item: any) => null}
        skipEntitlementCheck={true}
      />
    </Box>
  );
};

export default UserLeadsTable;
