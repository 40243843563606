import SubHeader from '../../../../components/sub-header';
import { t } from 'i18next';
import TEXT from '../../../../globals/translation-map';
import AppPagination from '../../../../components/app-pagination';
import FilterTags from '../../../../components/filter-tags';
import { Box } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import SearchInput from '../../../../components/search-input';
import { FilterTagProperties } from '../../../../components/filter-tags/types';

import HeaderRightButtons from './header-right-buttons';

const UserLeadsHeader: React.FC = () => {
  const [filterTags] = useState<FilterTagProperties>([]);

  const customComponentsForAppPagination: ReactElement[] = [
    <Box data-testid="search-input-container">
      <SearchInput
        disabled={false}
        onClickOfSearch={() => {}}
        placeholder={t(TEXT.COMMON.METADATA.BASE.SEARCH_EMAIL)}
        searchHistory="userLeadsSearchHistory"
        clearInput={() => {}}
        searchFieldValue={''}
        searchOnFieldCleared
        trimInput
      />
    </Box>,
    <HeaderRightButtons />,
  ];

  return (
    <Box data-testid="user-leads-header">
      <SubHeader title={t(TEXT.COMMON.TITLE.USER_LEADS)} actionComponents={[]} />
      <AppPagination
        customComponents={customComponentsForAppPagination}
        currentPage={0}
        limit={100}
        totalRecordCount={200}
        onClickActionForNext={() => {}}
        onClickActionForPrevious={() => {}}
      />
      {!!filterTags.length && <FilterTags filterTags={filterTags} />}
    </Box>
  );
};

export default UserLeadsHeader;
