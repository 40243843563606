import { useState, useEffect, useRef } from 'react';
import { AppState, useDispatchTs } from '../../store';
import { useSelector } from 'react-redux';
import { ApplicationName } from '../../constants';
import { getApplicationsParties } from '../../store/metadata/actions';
import { INVITATION_APPLICATIONS } from '../../services/invitations';

export const VisionLinkPages = {
  ACCESS_REQUESTS: 'AccessRequests',
  INVITATIONS: 'Invitations',
  MANAGE_ACCESS: 'ManageAccess',
};

export const useVisionLinkRestricted = (
  currentPage: string,
  ccid: string,
  applicationId: string = INVITATION_APPLICATIONS.VISION_LINK,
): { showBlueBox: boolean; canManageVLAccess: boolean; loading: boolean } => {
  const {
    metadata: { applicationParties },
  } = useSelector((state: AppState) => state.shared);
  const dispatch = useDispatchTs();
  const { environmentVariables } = useSelector((state: AppState) => state.login);

  const [loading, setLoading] = useState<boolean>(true);
  const [applicationVisionLink, setApplicationVisionLink] = useState<any | null>(null);
  const activeRequest = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (!ccid) return;
    const fetchApplicationData = async () => {
      if (applicationParties?.[ccid]) {
        const application = applicationParties?.[ccid]?.find(
          (app) => app?.applicationName === ApplicationName.VISIONLINK,
        );
        setApplicationVisionLink(application);
        setLoading(false);
      } else if (!activeRequest.current.has(ccid)) {
        activeRequest.current.add(ccid);
        try {
          await dispatch(getApplicationsParties(ccid));
        } catch (error) {
          console.error('Failed to fetch application data:', error);
          setApplicationVisionLink(null);
        } finally {
          activeRequest.current.delete(ccid);
          setLoading(false);
        }
      }
    };

    fetchApplicationData();
  }, [ccid, applicationParties, dispatch]);

  const isRestrictedParty = applicationVisionLink?.parties?.[0]?.isRestrictedParty || false;
  const hasCustomerAdmin = (applicationVisionLink?.parties?.[0]?.administratorsCount || 0) > 0;

  const getPageLogic = () => {
    switch (currentPage) {
      case VisionLinkPages.ACCESS_REQUESTS:
      case VisionLinkPages.MANAGE_ACCESS:
        if (isRestrictedParty && hasCustomerAdmin) {
          return {
            canManageVLAccess: false,
            showBlueBox: true,
          };
        }
        if (isRestrictedParty && !hasCustomerAdmin) {
          return {
            canManageVLAccess: true,
            showBlueBox: false,
          };
        }
        return {
          canManageVLAccess: true,
          showBlueBox: false,
        };

      case VisionLinkPages.INVITATIONS:
        if (!isRestrictedParty && hasCustomerAdmin) {
          return {
            canManageVLAccess: false,
            showBlueBox: true,
          };
        }
        if (isRestrictedParty && hasCustomerAdmin) {
          return {
            canManageVLAccess: false,
            showBlueBox: true,
          };
        }
        return {
          canManageVLAccess: true,
          showBlueBox: false,
        };

      default:
        return {
          canManageVLAccess: true,
          showBlueBox: false,
        };
    }
  };

  if (
    !environmentVariables?.restrictedAccountsFlag ||
    applicationId === INVITATION_APPLICATIONS.PCC
  ) {
    return { canManageVLAccess: true, showBlueBox: false, loading: false };
  }

  if (loading) {
    return { canManageVLAccess: false, showBlueBox: false, loading: true };
  }

  return { ...getPageLogic(), loading };
};
