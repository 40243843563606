import { Badge, Box, Button, Link, Typography } from '@mui/material';
import FilterIcon from '../../../../../assets/icons/filter-icon';
import TEXT from '../../../../../globals/translation-map';
import React from 'react';
import useStyles from '../styles';
import { useTranslation } from 'react-i18next';

const HeaderRightButtons = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Box display="flex">
      <Box className={classes.rightButtonContainer}>
        <Box display="flex" alignItems="center">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button
                disabled={false}
                data-testid="user-leads-filter-button"
                className={classes.filterButton}
                variant="contained"
                color="secondary"
                startIcon={
                  <Badge
                    color="secondary"
                    variant="dot"
                    data-testid="selected-filter-badge"
                    invisible={true}
                  >
                    <FilterIcon width="16px" height="16px" stroke="#43485C" />
                  </Badge>
                }
                onClick={() => {}}
              >
                {t(TEXT.COMMON.FILTERS_BUTTON)}
              </Button>
            </Box>
          </Box>
          <Typography className={classes.dividerText} alignSelf="baseline">
            |
          </Typography>
          <Link data-testid="user-leads-clear-all-filters" onClick={() => {}} underline="hover">
            {t(TEXT.COMMON.CLEAR_ALL)}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderRightButtons;
