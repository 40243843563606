import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box, TextField } from '@mui/material';
import useStyles from './styles';

export interface IDropDownValue {
  title: string;
  code: string;
  index?: React.Key;
}
type DropdownProperties = {
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  dropdownValues?: Array<IDropDownValue>;
  callbackFunction: Function;
  customClass?: string;
  required?: boolean;
  showNoneOption: boolean;
  selectedValue?: string;
  disabled?: boolean;
  error?: string;
};
const Dropdown = (props: DropdownProperties) => {
  const {
    label = '',
    variant,
    dropdownValues = [],
    showNoneOption,
    callbackFunction,
    customClass,
    required = false,
    selectedValue = '',
    disabled,
    error,
  } = props;
  const { classes } = useStyles();
  const [selection, setSelection] = React.useState(selectedValue ?? '');

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    callbackFunction(value);
    setSelection(value as string);
  };

  useEffect(() => {
    setSelection(selectedValue as string);
  }, [selectedValue]);

  return (
    <FormControl
      required={required}
      className={classes.formControl}
      data-testid="app-drop-down-container"
    >
      <Box>
        <TextField
          variant={variant ?? 'standard'}
          InputLabelProps={{
            shrink: true,
          }}
          data-testid={label?.replace(/ /g, '-').toLocaleLowerCase() || 'dropdown-textfield'}
          id={label?.replace(/ /g, '-').toLocaleLowerCase() || 'dropdown-label'}
          className={customClass ? customClass : classes.textField}
          select
          label={label}
          value={selection}
          disabled={disabled || false}
          error={error !== undefined}
          helperText={error}
          onChange={handleChange}
          required={required}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            },
          }}
        >
          {showNoneOption && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {dropdownValues?.map((value) => {
            return (
              <MenuItem
                value={value.code}
                key={value.index}
                data-testid={value?.title?.replace(/ /g, '-').toLocaleLowerCase()}
              >
                {value.title}
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </FormControl>
  );
};

export default Dropdown;
