import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import dayjs from 'dayjs';
import checkEnvironment from './utilities';
import { DevEnvironments } from './environments';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    load: 'currentOnly',
    debug: DevEnvironments.includes(checkEnvironment()),
    backend: {
      loadPath: '/locales/{{lng}}.json?v=5.9.0',
    },
  });

i18n?.services?.formatter?.add('date', (value, lng, options) =>
  dayjs(value).format(options.dateFormat),
);

export { default } from 'i18next';
