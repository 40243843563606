import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Backdrop } from '@mui/material';
import SubHeader from '../../components/sub-header';
import AssetRecommendationColumns from './components/columns';
import useStyles from './styles';
import { AppState, useDispatchTs } from '../../store';
import VariableRowInfiniteScrollTable from '../../components/variable-row-infinite-scroll-table';
import getScreenWidth from '../../hooks/get-screen-width';
import AppPagination from '../../components/app-pagination';
import Loading from '../../components/loading';
import getAssetRecommendationsData from '../../store/asset-recommendations/actions';
import { assetOwnershipActionNames } from '../../store/asset-ownership/types';
import { useNavigate } from 'react-router';

import {
  AssetRecommendationFilterType,
  assetRecommendationsActionNames,
} from '../../store/asset-recommendations/types';
import { AssetRecommendationsRequest } from '../../services/customer-asset-workflow';
import Filters from './components/advance-filters';
import { FilterTagProperties } from '../../components/filter-tags/types';
import FilterTags from '../../components/filter-tags';
import { StringEqualsFilter } from '../../entities/customer-master-v1/models';
import NoDataComponent from '../../components/variable-row-infinite-scroll-table/components/no-data-component';
import searchWarningLogo from '../../assets/images/search-warning.png';
import clipboardLogo from '../../assets/images/clipboard.png';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';
import { MODULE_CODES, loginActionNames } from '../../store/login/types';
import { SearchAssetRecommendationResponse } from '../../entities/customerAssetWorkflow-v1/searchAssetRecommendationResponse';

export type AssetRecommendationFilters = {
  dealerCode?: string[];
};

const AssetRecommendation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTs();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const screenWidth = getScreenWidth();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortOptionsOpen, setSortOptionsOpen] = useState<boolean>(false);
  const [sortingSelected, setSortingSelected] = useState<boolean>(false);

  const [limit] = useState<number>(100);
  const { environmentVariables } = useSelector((state: AppState) => state.login);
  const {
    isLoadingAssetRecommendations,
    totalCount,
    assetRecommendationsData,
    assetRecommendationFilters,
    filters,
  } = useSelector((state: AppState) => state.assetRecommendations);
  const [assetRecommendationfilters, setAssetRecommendationFilters] =
    useState<AssetRecommendationFilters>();
  const [filterTags, setFilterTags] = useState<FilterTagProperties | undefined>();
  const { customSettings } = useSelector((state: AppState) => state.login);

  const handleSortChange = (newSort: string) => {
    dispatch({
      type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_SORT_BY_VALUE,
      payload: [newSort],
    });
  };
  const handleOrderChange = (newOrder: string) => {
    dispatch({
      type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATIONS_ORDER_BY_VALUE,
      payload: [newOrder],
    });
  };

  const fetchData = () => {
    const fetchRequest: AssetRecommendationsRequest = {
      limit,
    };

    let filterNumber = 0;
    let advanceFilterNumber = 0;
    let logicalExpressionForSearchInput = '';
    let logicalExpressionForFilters = '';
    const requestFilters: Array<StringEqualsFilter> = [];
    if (assetRecommendationfilters && Object.keys(assetRecommendationfilters).length > 0) {
      if (assetRecommendationfilters && Object.keys(assetRecommendationfilters).length > 0) {
        logicalExpressionForFilters = '(';
        if (assetRecommendationfilters.dealerCode) {
          logicalExpressionForFilters = `${
            advanceFilterNumber === 0 ? '(' : `${logicalExpressionForFilters} &`
          } $${filterNumber}`;
          requestFilters.push({
            propertyName: 'dealerCode',
            type: 'stringEquals',
            values: assetRecommendationfilters.dealerCode,
          });
          filterNumber += 1;
          advanceFilterNumber += 1;
        }
        if (advanceFilterNumber !== 0) {
          logicalExpressionForFilters = `${logicalExpressionForFilters} )`;
        }
      }
      let logicalExpression = '';
      if (logicalExpressionForSearchInput !== '' && logicalExpressionForFilters !== '') {
        logicalExpression = `${logicalExpressionForSearchInput} & ${logicalExpressionForFilters}`;
      } else if (logicalExpressionForSearchInput !== '' && logicalExpressionForFilters === '') {
        logicalExpression = `${logicalExpressionForSearchInput}`;
      } else if (logicalExpressionForSearchInput === '' && logicalExpressionForFilters !== '') {
        logicalExpression = `${logicalExpressionForFilters}`;
      }
      fetchRequest.body = {
        logicalExpression,
        filters: requestFilters,
      };
    }

    dispatch(
      getAssetRecommendationsData(fetchRequest, {
        pageNumber: currentPage ?? 0,
        sortBy: filters.sortByRules.toString(),
        orderBy: filters.orderByRules.toString(),
      }),
    );
  };

  useEffect(() => {
    dispatch({
      type: loginActionNames.SET_MODULE_CODE,
      payload: MODULE_CODES.ASSET_MANAGEMENT,
    });
  }, []);
  useEffect(() => {
    fetchData();
  }, [limit, assetRecommendationfilters, currentPage, filters]);

  useEffect(() => {
    if (assetRecommendationFilters) {
      setAssetRecommendationFilters(assetRecommendationFilters);
      setupFilterTags(assetRecommendationFilters);
    }
  }, [assetRecommendationFilters]);

  const removeFilterbyType = (
    filterTagType: AssetRecommendationFilterType,
    filterTagValue?: string,
  ) => {
    dispatch({
      type: assetRecommendationsActionNames.DELETE_ASSET_RECOMMENDATION_FILTER_VALUE_BY_TYPE,
      payload: { propertyName: filterTagType, propertyValue: filterTagValue },
    });
  };

  const setupFilterTags = (filtersSetup: AssetRecommendationFilters) => {
    let newFilterTags: FilterTagProperties = [];
    if (filtersSetup && Object.keys(filtersSetup).length > 0 && filtersSetup.dealerCode) {
      for (const code of filtersSetup.dealerCode) {
        newFilterTags.push({
          code,
          removeFunction: () => removeFilterbyType(AssetRecommendationFilterType.dealerCode, code),
        });
      }
    }
    newFilterTags && setFilterTags(newFilterTags);
  };

  const applyRecommendationFilters = (values: { dealerCode?: string[] }) => {
    const inputFilters: AssetRecommendationFilters = {};
    if (values?.dealerCode?.length) {
      inputFilters.dealerCode = values.dealerCode;
    }
    setAssetRecommendationFilters(inputFilters);
    dispatch({
      type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATION_FILTERS,
      payload: inputFilters,
    });
    setCurrentPage(0);
  };

  const handleClearFilters = () => {
    setCurrentPage(0);
    dispatch({
      type: assetRecommendationsActionNames.SET_ASSET_RECOMMENDATION_FILTERS,
      payload: {},
    });
    setAssetRecommendationFilters({});
  };

  const handleClickForPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleClickForNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const ownershipChange = (row: SearchAssetRecommendationResponse) => {
    dispatch({
      type: assetOwnershipActionNames.CLEAR_ASSET_OWNERSHIP,
    });
    dispatch({
      type: assetOwnershipActionNames.SET_SELECTED_ASSET_RECOMMENDATION,
      payload: row,
    });
    navigate('/ownership-change');
  };
  const customComponentsForAppPagination: ReactElement[] = [
    <Box data-testid="search-input-container" width={40}></Box>,
    <Box display="flex">
      <Box className={classes.rightButtonContainer}>
        <Filters
          disabled={!!isLoadingAssetRecommendations}
          onClickOfApply={applyRecommendationFilters}
          clearFilters={handleClearFilters}
          selectedFilters={
            (assetRecommendationfilters && Object.keys(assetRecommendationfilters).length > 0) ||
            false
          }
          userSelectedFilters={assetRecommendationfilters}
        />
      </Box>
    </Box>,
  ];
  return (
    <Box data-testid="asset-recommendations-container">
      <Backdrop
        invisible
        open={!!isLoadingAssetRecommendations}
        style={{ zIndex: 2 }}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
        }}
      >
        <Loading blocks={environmentVariables.blocksComponents} />
      </Backdrop>
      <SubHeader title={t(TEXT.COMMON.TITLE.ASSET_RECOMMENDATIONS)} />
      <AppPagination
        customComponents={customComponentsForAppPagination}
        currentPage={currentPage}
        limit={limit}
        totalRecordCount={totalCount ?? 0}
        onClickActionForPrevious={handleClickForPrevious}
        onClickActionForNext={handleClickForNext}
        previousButtonDisabled={currentPage === 0 || isLoadingAssetRecommendations}
        isLoading={isLoadingAssetRecommendations}
      />
      {customSettings?.filterTags && <FilterTags filterTags={filterTags} expandable={true} />}
      <Box>
        <VariableRowInfiniteScrollTable
          rows={assetRecommendationsData ?? []}
          nonExpandedRowSizeBasedOnScreenRes={{ xl: 80, lg: 80, md: 96, sm: 160, xs: 160 }}
          expandedRowSizeBasedOnScreenRes={{
            xl: 180,
            lg: 180,
            md: 180,
            sm: 180,
            xs: 180,
          }}
          screenWidth={screenWidth}
          columns={AssetRecommendationColumns({
            sortOptionsOpen,
            setSortOptionsOpen,
            handleSortChange: handleSortChange,
            handleOrderChange: handleOrderChange,
            sortBy: filters?.sortByRules?.toString() as string,
            orderBy: filters?.orderByRules?.toString() as string,
            setSortingSelected,
            sortingSelected,
            classes: classes,
            ownershipChange: ownershipChange,
          })}
          hasNextPage={false}
          height={window.innerHeight - 0.34 * window.innerHeight}
          isNextPageLoading={isLoadingAssetRecommendations}
          loadNextPage={() => {}}
          expandedRowFlag={false}
          expandedRowComponent={() => null}
          skipEntitlementCheck
          noDataComponent={
            isLoadingAssetRecommendations ? (
              <> </>
            ) : assetRecommendationsData.length === 0 &&
              assetRecommendationfilters?.dealerCode !== undefined ? (
              <NoDataComponent
                line1={t(TEXT.COMMON.METADATA.ERRORS.NO_RESULTS_FOUND)}
                line2={t(TEXT.COMMON.METADATA.BASE.TRY_REVISING_CRITERIA)}
                searchWarningImage={searchWarningLogo}
              />
            ) : (
              <NoDataComponent
                line1={t(TEXT.ASSET_RECOMMENDATION.ERRORS.NO_ASSET_RECOMMENDATION)}
                searchWarningImage={clipboardLogo}
              />
            )
          }
        />
      </Box>
    </Box>
  );
};

export default AssetRecommendation;
