import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Link, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ApplicationDisplayProperties,
  ApplicationDropdownOptionType,
  selectAllID,
  selectAllOption as selectAllDropdown,
} from '../../types';
import TagPool from '../../../../../../components/tag-pool';
import useStyle from './styles';
import TaglessMultiselect from '../../../../../../components/tagless-multiselect';
import { AccessType } from '../../../../../../entities/user-onboarding-v1/accessType';
import {
  AccessType as AccessTypeInvitation,
  INVITATION_APPLICATIONS,
} from '../../../../../../services/invitations';
import { Role } from '../../../../../../entities/user-onboarding-v1/role';
import { AppState } from '../../../../../../store';
import Loading from '../../../../../../components/loading';
import { nameIdPairArray } from '../../../../../../globals/generic-types';
import { RoleType } from '../../../../../../store/invitations/types';
import ErrorBox from '../../../../../../components/error-box';
import TEXT from '../../../../../../globals/translation-map';
import {
  useVisionLinkRestricted,
  VisionLinkPages,
} from '../../../../../../hooks/use-vision-link-restricted';
import InlineNotification from '../../../../../../components/inline-notification';

const ApplicationDisplay = (props: ApplicationDisplayProperties) => {
  const {
    application,
    dciLoadingFlag,
    edit,
    nameClass,
    options,
    setValue,
    titleClass,
    value,
    accessRequestModal = false,
    limit = 3,
    error,
    partyNumber,
  } = props;
  const { classes } = useStyle();

  const [expandDCITags, setExpandDCITags] = useState<boolean>(false);
  const [selectAllOption, setSelectAllOption] = useState<boolean>(false);
  const [disassociatedTextFlag, setDisassociatedTextFlag] = useState<boolean>(false);
  const { applicationRoles, isLoadingRole } = useSelector(
    (state: AppState) => state.shared.metadata,
  );
  const { canManageVLAccess, showBlueBox } = useVisionLinkRestricted(
    VisionLinkPages.INVITATIONS,
    partyNumber || '',
    application.applicationId,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (
      application.applicationId === INVITATION_APPLICATIONS.PCC &&
      options.some((option) => {
        return option.applicationKey === INVITATION_APPLICATIONS.PCC;
      })
    ) {
      setDisassociatedTextFlag(false);
      const dcnMatchList = application.accessIdentifiers.filter(
        (applicationId: AccessTypeInvitation) => {
          return options.some((option) => {
            return option.id === applicationId.identifier;
          });
        },
      );
      if (dcnMatchList.length !== application.accessIdentifiers.length) {
        setDisassociatedTextFlag(true);
      }
    } else if (
      application.applicationId === INVITATION_APPLICATIONS.PCC &&
      !dciLoadingFlag &&
      options.length === 0 &&
      application.accessIdentifiers.length > 0
    ) {
      setDisassociatedTextFlag(true);
    }
  }, [application, options]);

  const filteredValues = useMemo(() => {
    if (Array.isArray(value) && application.applicationId === INVITATION_APPLICATIONS.PCC) {
      return value.filter((item) => {
        const parts = item.id.split('|');
        const dcn = parts.length > 1 ? parts[1] : parts[0];
        return options.some((opt) => opt.id?.split('|')[1] === dcn);
      });
    }
    return value;
  }, [value, options]);
  const showMoreLink = (
    <Box className={classes.linkButton} pb={1} pt={edit ? 1 : 2}>
      {application.accessType === AccessType.DCI &&
        filteredValues !== undefined &&
        filteredValues.length >= limit && (
          <Link
            className={classes.noWrap}
            underline="none"
            variant="subtitle1"
            data-testid="show-hide-application-ids-button"
            onClick={() => {
              setExpandDCITags(!expandDCITags);
            }}
          >
            {expandDCITags
              ? `Show Less Dealer Customers (${filteredValues?.length})`
              : `Show All Dealer Customers (${filteredValues?.length})`}
          </Link>
        )}
    </Box>
  );

  return (
    <Grid container pb={4} data-testid={`${application.applicationId}-application-grid`}>
      {!accessRequestModal && (
        <>
          <Grid item xs={6} md={6} className={titleClass}>
            {t(TEXT.COMMON.FILTERS.INPUT_LABELS.APPLICATION)}
          </Grid>
          <Grid item xs={6} md={6} className={edit ? classes.dropdownLabel : classes.roleLabel}>
            {edit &&
              !error &&
              (application.accessType === AccessType.DCI
                ? !dciLoadingFlag && t(TEXT.INVITATIONS.SELECT_DEALER_CUSTOMER)
                : !isLoadingRole && t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.SELECT_ROLE))}
            {!edit && application.accessType !== AccessType.DCI && t(TEXT.COMMON.ROLE)}
          </Grid>
          <Grid
            data-testid={`${application.applicationId}-nameTestid`}
            item
            xs={6}
            md={6}
            pt={1}
            pb={1}
            className={nameClass}
          >
            {application.applicationName}
          </Grid>
        </>
      )}
      {error && (
        <Grid item xs={12}>
          <ErrorBox />
        </Grid>
      )}
      {value !== undefined && !error && (
        <Grid item xs={accessRequestModal ? 12 : 6} md={accessRequestModal ? 12 : 6}>
          {edit && application.accessType === AccessType.DCI && !dciLoadingFlag ? (
            <TaglessMultiselect
              checkSelectAll={options.length === filteredValues.length}
              noOptionsText={t(TEXT.ACCESS_MANAGEMENT.INFO.NO_DEALER_CUSTOMERS_ASSOCIATED)}
              setValue={(tagValue: nameIdPairArray) => {
                if (
                  tagValue.some((tag) => {
                    return tag.id === selectAllID;
                  })
                ) {
                  if (selectAllOption) {
                    setValue(application.applicationId, []);
                    setSelectAllOption(false);
                  } else {
                    setSelectAllOption(true);
                    setValue(application.applicationId, options);
                  }
                } else {
                  if (selectAllOption) {
                    setValue(application.applicationId, []);
                    setSelectAllOption(false);
                  } else {
                    setValue(application.applicationId, tagValue);
                  }
                }
              }}
              value={value}
              placeholderText={t(TEXT.COMMON.FILTERS.PLACEHOLDERS.SELECT_ALL_THAT_APPLY)}
              options={
                options.length
                  ? [{ ...selectAllDropdown, name: t(selectAllDropdown.name) }, ...options]
                  : []
              }
              rowSubtitle={true}
            />
          ) : (
            edit &&
            application.accessType === AccessType.DCI && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <Loading />
              </Box>
            )
          )}
          {edit && application.accessType !== AccessType.DCI && !isLoadingRole ? (
            <Select
              size="medium"
              displayEmpty
              disabled={!canManageVLAccess}
              data-testid={`${application.applicationId}-dropdown`}
              id={`${application.applicationId}-dropdown`}
              variant="standard"
              fullWidth
              value={value.length > 0 && value[0].id ? value[0].id : ''}
              onChange={(event) => {
                setValue(application.applicationId, [
                  {
                    id: event.target.value,
                    name: event.target.value,
                  },
                ]);
              }}
            >
              {options
                .filter(
                  (role: ApplicationDropdownOptionType) =>
                    !(role.name === RoleType.SOS_PILOT_PRIVILEGE),
                )
                .map((option: ApplicationDropdownOptionType, parentIndex: number) => (
                  <MenuItem
                    key={`li-${application.applicationId}-${option.id}-${parentIndex}`}
                    data-testid={`li-${application.applicationId}-${option.id}-${parentIndex}`}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            application.accessType !== AccessType.DCI &&
            !edit && (
              <Box
                display="flex"
                justifyContent="left"
                alignItems="center"
                height="100%"
                width="100%"
                pl="24px"
                className={nameClass}
              >
                {options && options.length > 0 && application.accessType !== AccessType.DCI
                  ? value.length > 0
                    ? applicationRoles?.find((role: Role) => role.roleId === value[0].id)?.roleName
                    : ''
                  : ''}
              </Box>
            )
          )}
        </Grid>
      )}
      {value !== undefined && !error && application.accessType === AccessType.DCI && (
        <>
          {!edit && showMoreLink}
          <TagPool
            id={application.applicationId}
            value={filteredValues}
            setValue={(tagValue: nameIdPairArray) => {
              setValue(application.applicationId, tagValue);
            }}
            hideRemoveButton={!edit}
            showAll={expandDCITags}
            limit={limit}
            position={'left'}
            scrollable={accessRequestModal}
          />
          {disassociatedTextFlag && (
            <Typography paddingTop="10px" color="red">
              {t(TEXT.ACCESS_MANAGEMENT.INFO.ONE_OR_MORE_DEALER_CUSTOMER)}
            </Typography>
          )}
        </>
      )}
      {edit && showMoreLink}
      {showBlueBox && (
        <InlineNotification
          text={t(TEXT.INVITATIONS.RESTRICTED_ACCOUNT_WARNING_MESSAGE)}
          testId="vl-inline-notification"
        />
      )}
    </Grid>
  );
};

export default ApplicationDisplay;
