import { invitationsActionNames, InvitationsActionTypes, InvitationsState } from './types';

const initialState: InvitationsState = {
  invitations: [],
  cursorsForInvitations: {
    nextCursors: [],
    currentPage: undefined,
  },
  invitationSearchValue: '',
  invitationsFilters: {
    sortByRules: ['createdTime'],
    orderByRules: ['DESC'],
    selectedAccount: '',
    manualSelectedAccountEntry: false,
    selectedCustomers: [],
    selectedApplications: [],
    selectedInvitationStatus: [],
    selectedTimeRangeButton: undefined,
  },
  createInvitations: {
    selectedCustomer: undefined,
    accounts: [],
    selectedLanguage: '',
    invitationsResponse: undefined,
  },
  isUsersPrimaryParty: true,
  totalInvitationCount: 0,
};

const invitationsReducer = (
  state: InvitationsState = initialState,
  action: InvitationsActionTypes,
): InvitationsState => {
  switch (action.type) {
    case invitationsActionNames.SET_INVITATION_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case invitationsActionNames.SET_INVITATIONS: {
      return {
        ...state,
        invitations: action.payload,
      };
    }
    case invitationsActionNames.SET_INVITATION_SEARCH_VALUE: {
      return {
        ...state,
        invitationSearchValue: action.payload,
      };
    }

    case invitationsActionNames.CLEAR_INVITATIONS: {
      return {
        ...state,
        invitations: initialState.invitations,
      };
    }

    case invitationsActionNames.SET_CURSORS_FOR_ITEMS: {
      return {
        ...state,
        cursorForItems: action.payload,
      };
    }
    case invitationsActionNames.SET_CURSORS_FOR_INVITATIONS: {
      const nextCursors = [...(state.cursorsForInvitations?.nextCursors ?? [])];
      if (action.payload.currentPage >= state.cursorsForInvitations?.nextCursors.length) {
        nextCursors.push(action.payload.cursor);
      }
      return {
        ...state,
        cursorsForInvitations: {
          nextCursors,
          currentPage: action.payload.currentPage,
        },
      };
    }
    case invitationsActionNames.RESET_CURSORS_FOR_INVITATIONS: {
      return {
        ...state,
        cursorsForInvitations: { currentPage: 0, nextCursors: [] },
      };
    }
    case invitationsActionNames.SET_CREATE_INVITE_LINKS: {
      return {
        ...state,
        createLinks: action.payload,
      };
    }
    case invitationsActionNames.SET_IS_LOADING_INVITATIONS: {
      return {
        ...state,
        isLoadingInvitations: action.payload,
      };
    }
    case invitationsActionNames.SET_INVITATIONS_TOTAL_COUNT: {
      return {
        ...state,
        totalInvitationCount: action.payload,
      };
    }
    case invitationsActionNames.SET_IS_LOADING_COUNT: {
      return {
        ...state,
        isLoadingInvitationsCount: action.payload,
      };
    }
    case invitationsActionNames.SET_INVITATION_FILTERS: {
      return {
        ...state,
        invitationsFilters: { ...action.payload },
      };
    }
    case invitationsActionNames.CLEAR_INVITATION_FILTERS: {
      return {
        ...state,
        invitationsFilters: initialState.invitationsFilters,
      };
    }
    case invitationsActionNames.CLEAR_CREATE_INVITAIONS: {
      return {
        ...state,
        createInvitations: initialState.createInvitations,
      };
    }
    case invitationsActionNames.SET_ACCOUNTS: {
      return {
        ...state,
        createInvitations: {
          ...state.createInvitations,
          accounts: action.payload,
        },
      };
    }
    case invitationsActionNames.SET_LANGUAGE: {
      return {
        ...state,
        createInvitations: {
          ...state.createInvitations,
          selectedLanguage: action.payload,
        },
      };
    }
    case invitationsActionNames.SET_INVITATIONS_RESPONSE: {
      return {
        ...state,
        createInvitations: {
          ...state.createInvitations,
          invitationsResponse: action.payload,
        },
      };
    }
    case invitationsActionNames.SET_SELECTED_CUSTOMER_FOR_INVITATIONS: {
      return {
        ...state,
        createInvitations: {
          ...state.createInvitations,
          selectedCustomer: action.payload,
        },
      };
    }
    case invitationsActionNames.SET_IS_USERS_PRIMARY_PARTY: {
      return {
        ...state,
        isUsersPrimaryParty: action.payload,
      };
    }
    case invitationsActionNames.SET_INVITATIONS_SORT_BY_VALUE: {
      return {
        ...state,
        invitationsFilters: {
          ...state.invitationsFilters,
          sortByRules: action.payload,
        },
      };
    }
    case invitationsActionNames.SET_INVITATIONS_ORDER_BY_VALUE: {
      return {
        ...state,
        invitationsFilters: {
          ...state.invitationsFilters,
          orderByRules: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default invitationsReducer;
